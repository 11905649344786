import {useEffect, useState} from "react"
import styles from "./Video.module.css"

export default function Video({closeVideo}){
	return(
    <div className={styles.videoContainer}>
      <div>
        <div className={styles.closeVideo} onClick={closeVideo}>
          Close
        </div>
        <div className={styles.videoSizing}>
          <div className={styles.videoResponsiveContainer}>
            <iframe src="https://www.loom.com/embed/5a297793a03c4ae9908c79e655cd6c96" frameBorder="0"></iframe>
          </div>
        </div>
      </div>
    </div>
	)
}