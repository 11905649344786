import {useState, useEffect, ReactComponent, forwardRef, useContext} from "react"
import {ReactComponent as DragHandle} from "./drag-handle.svg"
import { Card, Button, ButtonSize, ButtonTheme, IconButton, Text, ButtonType, Heading } from "@qwilr/kaleidoscope";
import { TextInput, TextInputSize } from "@qwilr/kaleidoscope";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import {
  arrayMove,
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { nanoid } from 'nanoid'

import Loader from "./../Loader/Loader"
import LoadingElipsis from "./../Loader/LoadingElipsis/LoadingElipsis"
import LoadingStatements from "./../Loader/LoadingStatements/LoadingStatements"
import Error from "./../Error/Error"
import styles from "./Outline.module.css"
import {useFakeData} from "./../App"
import fetchWithRetry from "fetch-retry"
const fetch = fetchWithRetry(window.fetch)
const REQUEST_TIMEOUT = 1500
const RETRIES = 2
const APP_MOUNT_PATH = process.env.REACT_APP_PROD_MOUNT_PATH_URL

export default function Outline({description, structure, setStructure, nextStep}){
  const [loaded, setLoaded] = useState(useFakeData ? true : false)
  const [error, setError] = useState(false)

  const generateOutline = () => {
    fetch(`${APP_MOUNT_PATH}/document-structure`, {
	    retries: RETRIES,
	    retryDelay: REQUEST_TIMEOUT,
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: JSON.stringify({description})
     })
    .then( (response) => {
      if(!response.ok){
        throw new Error(response)
        return setError(true)
      }
      
      response.json().then(onSuccess)
    })
    .catch( (err) => {
      console.error(error)
      return setError(true)
    }) 
  }

  const onSuccess = (outline) => {
    let outlineArray = outline
      .split(/\r?\n/)
      .filter( sectionName => sectionName.length )
      .map( (sectionName) =>
        ({
          name: sectionName,
          id: nanoid()
        })
      );

    // Sometimes the LLM ignores our array length instruction
    // So ensure we've only got six sections (for performance reasons)
    if(outlineArray.length > 6){
    	outlineArray = outlineArray.splice(0, 6)
    }

    setStructure(outlineArray)
    setLoaded(true)
  }

  // Optionally use dummy data
  const dummyGenerateOutline = () => { setStructure(fakeData) }
  const generateOutlineFn = useFakeData ? dummyGenerateOutline : generateOutline
  useEffect( () => { generateOutlineFn() }, [])

  let Content
  if(loaded){
    Content = <OutlineEditor
      structure={structure}
      setStructure={setStructure}
      description={description}
      nextStep={nextStep}
      regenerateOutline={ () => {
        setLoaded(false)
        generateOutline()
      }}
    />
  } else if(!loaded && !error) {
    Content = <OutlineLoadingState />
  } else if(error) {
    Content = <Error />
  }

  return(
    <div className={styles.outline}>
      {Content}
    </div>
  )
}

function OutlineLoadingState(){
  const loadingStatements = [
    "Building your outline",
    "Sweating the details",
    "Checking the thinking",
  ]

  return(
    <div className={styles.loader}>
      <Loader />
      <div className={styles.loaderText}>
        <Heading strong level="2">
          <LoadingStatements loadingStatements={loadingStatements} />
          <LoadingElipsis />
        </Heading>
      </div>
    </div>
  )
}



function OutlineEditor({description, structure, setStructure, nextStep, regenerateOutline}){
  return(
    <div className={styles.outlineEditor}>
      <header className={styles.header}>
        <Heading strong level="2">How does this outline look?</Heading>
        <div className={styles.subHeading}>
          <Text size="xl">Feel free to re-order, edit and make it your own.</Text>
        </div>
      </header>

      <SortableList
        structure={structure}
        setStructure={setStructure} />

      <div className={styles.actionContainer}>
        <button
          className={styles.button + " secondary"}
          onClick={regenerateOutline}
          size={ButtonSize.Large}
          type={ButtonType.Secondary}
          theme={ButtonTheme.Light}>
          Show me another
        </button>
        <button
          className={styles.button + " primary"}
          onClick={nextStep}
          size={ButtonSize.Large}
          type={ButtonType.Primary}
          theme={ButtonTheme.Light}>
          Looks good
        </button>
      </div>

    </div>
  )
}


function SortableList({structure, setStructure}) {
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const DragOverlayItem = forwardRef(({children, ...props}, ref) => {
    const section = structure.find( (section) => section.id === props.activeId)
    return (
      <div className={styles.mobileScrollFix} ref={ref}>
        <Card elevation={2}>
          <div className={styles.dragOverlayItem}>
            {section.name}
          </div>
        </Card>
      </div>
    )
  });

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}>
      <SortableContext
        items={structure}
        strategy={verticalListSortingStrategy}>
          {structure.map( (section, index) =>
            <SortableSection
              key={index}
              id={section.id}
              section={section}
              setStructure={setStructure}
              structure={structure}
              itemIndex={index} />
          )}
           <DragOverlay>
            { activeId &&
              <DragOverlayItem activeId={activeId}></DragOverlayItem>
            }
          </DragOverlay>
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setStructure((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function handleDragStart(event){
    setActiveId(event.active.id);
  };
}

function SortableSection({section, setStructure, structure, itemIndex, id}){

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});

  function updateSectionName(newSectionName){

    const newStructure = structure.map( (section, index) => {
      if(section.id !== id){
        return section
      } else {
        return {
          ...section,
          name: newSectionName
        }
      }
    })
    setStructure(newStructure)
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} >
      <Card elevation={1}>
        <div className={styles.draggableListItem}>
          <div
            className={styles.dragHandle}
            {...attributes}
            {...listeners}>
            <DragHandle />
          </div>
          <TextInput
            className={styles.textInput}
            labelHidden
            value={section.name}
            size={TextInputSize.Medium}
            placeholder={"Section name..."}
            onChange={updateSectionName}
          />
        </div>
      </Card>
    </div>
  )
}

const fakeData =[
	{
	  id: "0",
	  name: "Introduction"
	},
	{
	  id: "1",
	  name: "Overview of Acme Corporation's Financial Modelling Needs"
	},
	{
	  id: "2",
	  name: "Description of Our Financial Modelling Software"
	},
	{
	  id: "3",
	  name: "Key Features and Benefits"
	},
	{
	  id: "4",
	  name: "Technical Specifications"
	},
	{
	  id: "5",
	  name: "Implementation Plan"
	},
	{
	  id: "6",
	  name: "Pricing Options"
	},
	{
	  id: "7",
	  name: "ROI Impact"
	}
]
