import {useEffect, useState} from "react"
import styles from "./VideoTile.module.css"
import {ReactComponent as PlayButton} from "./play-button.svg"
import MarkTanner from "./mark-tanner.png"

export default function VideoTile({showVideo}){
	return(
		<div className={styles.tourVideo} onClick={showVideo}>
			<div className={styles.videoTitle}>
				<h3>AI Page Builder</h3>
				<h4>Founder Tour</h4>
			</div>
			<img className={styles.tanner} src={MarkTanner} alt="Mark Tanner, cofounder Qwilr"/>
			<div className={styles.playButton}>
				<PlayButton />
			</div>
		</div>
	)
}
