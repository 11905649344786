import {useEffect, useState} from "react"
import styles from "./CopyPageURL.module.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function CopyPageURL({url}){
	const [showCopiedMsg, setShowCopiedMsg] = useState(false)

	useEffect( () => {
		if(showCopiedMsg === true){
			const timer = setTimeout(() => {
				setShowCopiedMsg(false)
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [showCopiedMsg])
	
	const onCopied = () => {
		setShowCopiedMsg(true)
	}

	let classes = styles.copyToClipboard
	if(showCopiedMsg){
		classes += ` ${styles.showCopiedMsg}`
	}

	return(
		<div className={classes}>

			{ !showCopiedMsg &&
				<CopyToClipboard text={url} onCopy={onCopied}>
					<button className={"secondary"}>Copy link</button>
				</CopyToClipboard>
			}
			{ showCopiedMsg && 
				<p>Link copied to clipboard</p>
			}
		</div>
	)
}

					