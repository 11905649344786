import {useState, useEffect} from "react"
import styles from "./Sidebar.module.css"
import {ReactComponent as QwilrLogo} from "./../assets/qwilr-logo.svg"
import VideoTile from "./../Video/VideoTile/VideoTile"

export default function Sidebar({currentStep, setCurrentStep, structure, description, showVideo}){
	const steps = [
		{
			label: "Describe document",
			stepName: "description",
			isAvailable: () => true
		},
		{
			label: "Customise outline",
			stepName: "structure",
			isAvailable: () => {
				return (
					description.identity.length > 0 && 
					description.useCase.length > 0 && 
					description.receiver.length > 0 && 
					description.purpose.length > 0
				)
			}
		},
		{
			label: "Choose your style",
			stepName: "style",
			isAvailable: () =>
				structure.length > 0
		}
	]

	const handleClick = (step) => {
		if(step.isAvailable()){
			setCurrentStep(step.stepName)
		}
	}

	return(
		<div className={styles.sidebar}>
			<div className={styles.mainWrapper}>
				<header className={styles.header}>
					<a href="https://qwilr.com" target="_blank">
						<QwilrLogo />
					</a>
				</header>

				<ol className={styles.stepList}>
					{ 
						steps.map( (step, index) => {
							let className = styles.stepLabel
							if(step.stepName === currentStep){
								className += " " + styles.currentStep
							}
							return(
								<li key={index} className={className} onClick={ () => handleClick(step) }>
									<span className={styles.stepIndicator}></span>
									<span className={styles.stepName}>{step.label}</span>
								</li>
							)
						})
					}
				</ol>
			</div>

			<VideoTile showVideo={showVideo} />
		</div>
	)
}