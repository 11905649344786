import {useState, useEffect} from "react"
import styles from "./LoadingElipsis.module.css"

export default function LoadingElipsis({cycleTime = 500}){
	const [counter, setCounter] = useState(0)
	useEffect(() => {
    const interval = setInterval( () => {
    		setCounter( (counter + 1) % 4)
    	}, cycleTime
    );

    return () => clearInterval(interval);
  }, [counter]);

  const dots = Array.from(Array(counter))

  return(
  	<span className={styles.loadingElipsisContainer}>
  		{ dots.map( (item, index) => <span key={index}>.</span>) }
  	</span>
  )
}