import { getFingerprintId } from "@qwilr/fingerprint";

const FINGERPRINT_PUBLIC_KEY = process.env.REACT_APP_FINGERPRINT_PUBLIC_KEY

/*
	@dylan 28/6/2023 - note: since it's possible FingerprintJS/Segment will be blocked by
	some adblockers, we won't get telemetry on those users. The more bullet-proof,
	but complex, way to do this is to have Fingerprint initialise on the client-side
	and then send telemetry data, along with the fingerprint ID, to the server,
	where Segment events are fired.
*/

let loaded = false
const initialisingFactory = () =>
	new Promise( (resolve) => {
		// "analytics" here is the Segment analytics library
		// that will be added to "window" when the page is loaded
		window.analytics?.ready(async () => {
		  const fingerprintId = await getFingerprintId(FINGERPRINT_PUBLIC_KEY);
		  if (fingerprintId) {
		    window.analytics?.user().anonymousId(fingerprintId);
		  }
		  loaded = true
		  resolve()
		});
	})

let initialising = initialisingFactory()

export default {
	trackEvent: async (eventName, parameters) => {
		
		// If Segment analytics wasn't loaded; we try to initialise again.
		if(!loaded){
			initialising = initialisingFactory()
		}
		await initialising
		window.analytics?.track(eventName, parameters)
	}
}
