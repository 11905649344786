import LoadingBar from "react-top-loading-bar"
import {useEffect, useState, useRef} from "react"
import styles from "./LoaderBar.module.css"

export function LoaderBar({loadTime, neverLoads, onLoaded}){
	const [progress, setProgress] = useState(0)
	const loaderRef = useRef(null)
	const INTERVAL_SPEED = randomIntBetween(700, 1300)

	// For the loader to take approximately "loadTime" to load
	// we generate a percent increment, based upon the interval speed
	const baseProgressPercent = 100 / (loadTime / INTERVAL_SPEED)

	useEffect(() => {
		if(progress > 100){
			return
		}
    const interval = setInterval( () => {
    		// Add a little randomness here for realism
    		// const randomisedPercent = randomIntBetween(baseProgressPercent, baseProgressPercent + 5)
    		setProgress(progress + baseProgressPercent)
    	}, INTERVAL_SPEED
    );

    return () => clearInterval(interval);
  }, [progress]);

	return(
		<LoadingBar 
			className={styles.loaderBar}
			style={loaderBarStyles}
			containerClassName={styles.container} 
			progress={progress}
			shadow={false}
			onLoaderFinished={onLoaded} />
	)
}

export function LoaderBarNeverLoads(){
	const [progress, setProgress] = useState(0)
	const loaderRef = useRef(null)

	useEffect(() => {
		loaderRef.current.continuousStart()
	}, []);

	return(
		<LoadingBar 
			ref={loaderRef} 
			className={styles.loaderBar}
			style={loaderBarStyles}
			shadow={false}
			continuousStart={5, 1000}
			containerClassName={styles.container} 
			progress={progress}
			onLoaderFinished={() => setProgress(0)} />
	)	
}

function randomIntBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const loaderBarStyles = {
	position: "static",
  height: "8px",
  background: "linear-gradient(90deg, #0BDAC9 0%, #7366FF 100%)",
  zIndex: "10",
  borderRadius: "3px"
}
