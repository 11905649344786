import { Card, Heading } from "@qwilr/kaleidoscope";
import Loader from "./../../Loader/Loader"
import LoadingElipsis from "./../../Loader/LoadingElipsis/LoadingElipsis"
import LoadingStatements from "./../../Loader/LoadingStatements/LoadingStatements"
import styles from "./Explainer.module.css"

export default function Explainer({children}){

	const loadingStatements = [
		"Talking to Qwilr API",
		"Calibrating tone",
		"Cross checking",
		"Searching all knowledge",
		"Optimising your page"
	]

	return(
		<div className={styles.explainer}>
			<Loader />
			<div className={styles.loadingText}>
				<header className={styles.header}>
					<Heading strong level="2">
						<LoadingStatements loadingStatements={loadingStatements} />
						<LoadingElipsis />
					</Heading>
					<p className={styles.loadingExplainer}>Qwilr's AI is building your page. This can take a minute.</p>
				</header>
				
				<div className={styles.mobileInsert}>
					{children}
				</div>
				
				<div className={styles.productExplainer}>
					Qwilr is a document tool which creates compelling web-based content. That content generates engagement data, which is then used to power automations and workflows.
				</div>
			</div>
		</div>
	)


}


