import {useState, useEffect, useContext} from "react"
import { Card, Text, Heading, Spinner, SpinnerSize } from "@qwilr/kaleidoscope";
import {LoaderBar, LoaderBarNeverLoads} from "./LoaderBar/LoaderBar"
import Tick from "./tick.svg"
import LoadingIndicator from "./LoadingIndicator/LoadingIndicator"
import styles from "./PageBuildProgress.module.css"

const MAX_LOADER_SECS = 20
const MIN_LOADER_SECS = 5


function randomIntBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default function PageBuildProgress({sections}){
	const [loaders, setLoaders] = useState([])

	useEffect( () => {
		// One of the items needs to never finish - i.e. until the real load is completed.
		const waitingOnIndex = randomIntBetween(0, sections.length - 1)

		const loaders = sections.map( (sectionName, index) =>
			({
				name: sectionName,
				finishSpeed: randomIntBetween(MIN_LOADER_SECS * 1000, MAX_LOADER_SECS * 1000),
				loaded: false,
				neverLoads: waitingOnIndex === index
			})
		)

		setLoaders(loaders)
	}, [sections])


	const onLoaded = (sectionIndex) => {
		let updatedLoaders = loaders
		updatedLoaders[sectionIndex].loaded = true
		setLoaders([...updatedLoaders])
	}

	return(
		<div className={styles.progressContainer}>
			<div className={styles.contentWrapper}>
				<p className={styles.progressLabel}><Text size="xl">Build progress...</Text></p>
				{ 
					loaders.map( (section, index) => {
						let classes = styles.loadingCard
						if(section.loaded){
							classes += ` ${styles.loaded}`
						}
						return(
							<Card className={classes} elevation={1} key={index}>
								{ !section.loaded &&
									<div className={styles.spinner}>
										<LoadingIndicator />
									</div>
								}
								{ section.loaded &&
									<img className={styles.tick} src={Tick} alt="Tick" />
								}
								<div className={styles.main}>
									<div className={styles.sectionName}>
										<Heading level={3}>{section.name}</Heading>
									</div>
									{ !section.neverLoads && !section.loaded &&
										<LoaderBar 
											onLoaded={ () => onLoaded(index)}
											loadTime={section.finishSpeed} />
									}
									{ section.neverLoads &&
										<LoaderBarNeverLoads 
											onLoaded={ () => onLoaded(index)}
											loadTime={section.finishSpeed} />
									}
								</div>
							</Card>
						)

					})
				}
			</div>
		</div>
	)
}