import {useState, useEffect, ReactComponent} from "react"
import {Spinner, Card, Heading} from "@qwilr/kaleidoscope";
import styles from "./Loader.module.css"

export default function Loader({children}){
	return(
		<div className={styles.loader}>
			<Card className={styles.spinnerContainer} elevation={2}>
				<svg className={styles.loadingSpinner} fill="none" viewBox="0 0 82 82">
					<circle cx="41" cy="41" r="38.696" stroke="#81A2B2" strokeOpacity=".25" strokeWidth="3" />
					<path
						stroke="url(#a)"
						strokeLinecap="round"
						strokeWidth="3"
						d="M79.695 41C79.695 19.63 62.371 2.304 41 2.304S2.304 19.63 2.304 41 19.63 79.696 41 79.696"
					/>
					<defs>
						<linearGradient
							id="a"
							x1="79.695"
							x2="2.304"
							y1="79.696"
							y2="79.696"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FF6161" />
							<stop offset=".271" stopColor="#FFB961" />
							<stop offset=".536" stopColor="#00A99D" />
							<stop offset=".773" stopColor="#33AFFF" />
							<stop offset="1" stopColor="#716BF1" />
						</linearGradient>
					</defs>
				</svg>

				<svg className={styles.qwilrArrow} width="61" height="67" viewBox="0 0 61 67" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.23107 53.0603L8.64525 64.6998C8.93194 65.1586 9.33016 65.5368 9.80254 65.7989C10.2749 66.0611 10.806 66.1986 11.3459 66.1986C11.8858 66.1986 12.4169 66.0611 12.8892 65.7989C13.3616 65.5368 13.7598 65.1586 14.0465 64.6998L30.6026 37.67L18.2904 17.5195L1.13042 46.3323C0.51977 47.3516 0.205586 48.5218 0.223372 49.7107C0.241157 50.8996 0.590201 52.0599 1.23107 53.0603V53.0603Z" fill="#00A99D"/>
					<path d="M60.0906 46.3323L42.9306 17.5195L30.6016 37.7036L47.1744 64.7335C47.4611 65.1922 47.8594 65.5704 48.3317 65.8326C48.8041 66.0947 49.3352 66.2323 49.8751 66.2323C50.415 66.2323 50.9461 66.0947 51.4184 65.8326C51.8908 65.5704 52.289 65.1922 52.5757 64.7335L59.9899 53.094C60.6319 52.0877 60.9813 50.9219 60.9991 49.7275C61.0169 48.5331 60.7023 47.3573 60.0906 46.3323V46.3323Z" fill="#EF445A"/>
					<path d="M38.2187 9.59493L33.3207 1.80724C33.0348 1.34477 32.636 0.963134 32.1619 0.698481C31.6879 0.433828 31.1542 0.294922 30.6116 0.294922C30.0691 0.294922 29.5354 0.433828 29.0614 0.698481C28.5873 0.963134 28.1884 1.34477 27.9026 1.80724L23.0046 9.61175L18.291 17.5172L30.6033 37.7013L42.9323 17.6013L38.2187 9.59493Z" fill="#01445A"/>
				</svg>

			</Card>
			{children}
		</div>
	)
}