import {useState, useEffect} from "react"
import { Card, Button, ButtonSize, ButtonTheme, IconButton, Text, ButtonType, Heading } from "@qwilr/kaleidoscope";
import styles from "./Style.module.css"
import CreativeImage from "./creative.jpg"
import ModernImage from "./modern.jpg"
import HeritageImage from "./heritage.jpg"

export default function Style({style, setStyle, nextStep}){
	const styleList = [
		{
			name: "Colorful",
			image: CreativeImage
		},
		{
			name: "Modern",
			image: ModernImage
		},
		{
			name: "Heritage",
			image: HeritageImage
		}
	]

	return(
		<div>
			<header className={styles.header}>
				<Heading strong level="2">Choose your design...</Heading>
			</header>
			<ul className={styles.styleList}>
				{ 
					styleList.map( (styleItem, index) => {
						const isSelected = style === styleItem.name.toLowerCase() 
						const selectedClass = isSelected ? styles.selectedStyle : ""
						const elevationLevel = isSelected ? 2 : 1
						return(
							<li 
								key={index} 
								className={styles.styleItem}
								onClick={ () => setStyle(styleItem.name.toLowerCase()) }> 
								<Card  
									className={styles.styleTile + " " + selectedClass} 
									elevation={elevationLevel}>
									<img src={styleItem.image} alt={styleItem.name} />
								</Card>
								<div className={styles.styleName + " " + selectedClass}>
									<Text strong size="xl"> 
										{styleItem.name}
									</Text>
								</div>
							</li>
						)
					})
				}
			</ul>
			<div className={styles.actionContainer}>
        <button 
          className={styles.button + " primary"}
          onClick={nextStep}
          size={ButtonSize.Large} 
          type={ButtonType.Primary} 
          theme={ButtonTheme.Light}>
          Create page
        </button>
      </div>
		</div>
	)
}