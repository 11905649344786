import styles from "./PagePreview.module.css"
import {useState, useEffect} from "react"
import { Card, Text, Heading } from "@qwilr/kaleidoscope";
import QwilrLogo from "./../assets/qwilr-logo.svg"
import InfoModal from "./InfoModal/InfoModal"
import CopyPageURL from "./CopyPageURL/CopyPageURL"
import ConfettiExplosion from 'react-confetti-explosion';

export default function PagePreview({page}){
	const [showDismissedInfo, setShowDismissedInfo] = useState(true)

	useEffect( () => {
		if(showDismissedInfo ===  false){
			const timer = setTimeout(() => {
				window.Intercom('boot', {app_id: process.env.REACT_APP_INTERCOM_APP_ID}); 
				window.Intercom('startSurvey', process.env.REACT_APP_INTERCOM_SURVEY_ID);
			}, 7500);
			return () => clearTimeout(timer);
		}
	}
	, [showDismissedInfo])

	const editPage = () => {
		window.open(`${page.clientAppHost}/#/signup?entry_point=page_builder_ai&with_page=${page.secret}`)
	}

	const confettiProps = {
	  force: 0.8,
	  duration: 3500,
	  particleCount: 300,
	  width: 1600,
	}


	return(
		<div className={styles.pagePreview}>
			<header className={styles.header}>
				<a href="https://qwilr.com" className={styles.logoLink} target="_blank">
					<img src={QwilrLogo} className={styles.logo} alt="Qwilr logo"/>
				</a>
				<div className={styles.actions}>
					<div className={styles.copyURL}>
						<CopyPageURL url={page.link} />
					</div>
		      <button 
		      	onClick={editPage}
		      	className={styles.editButton + " primary"}>
		        Edit this page
		      </button>
		    </div>
			</header>
			<div className={styles.contentContainer}>
				{ showDismissedInfo &&
					<InfoModal onDismiss={ () => setShowDismissedInfo(false) } />
				}
				<iframe className={styles.frame} src={page.link} title="Dunno yet..." />
			</div>
			
			<div className={styles.confettiWrapper}>
				<div className={styles.confettiPosition}>
					<ConfettiExplosion {...confettiProps} />
				</div>
			</div>
		</div>
	)
}
