import {useState, useEffect} from "react"
import styles from "./CreatePage.module.css"
import Explainer from "./Explainer/Explainer"
import Error from "./../Error/Error"
import PageBuildProgress from "./PageBuildProgress/PageBuildProgress"
import {useFakeData} from "./../App"
import retry, {AbortError} from 'p-retry';
const APP_MOUNT_PATH = process.env.REACT_APP_PROD_MOUNT_PATH_URL

export default function CreatePage({structure, description, style, setPage, nextStep}){
	const [error, setError] = useState(false)
	const sectionNames = structure.map( (section) => section.name )

	const getPage = () => {
		const data = {
			structure: sectionNames.join("\n"),
			description,
			style
		}
		
		fetch(`${APP_MOUNT_PATH}/create-page`, {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			body: JSON.stringify(data)
		}).then( (response) => {

			if(!response.ok){
				throw new Error(response)
				return setError(true)
			}

			response.json().then( ({apiData, clientAppHost}) => {
				// If we get an error back from the Qwilr API
				if(apiData.statusCode > 400){
					throw new Error(apiData.statusText)
					setError(true)
				} else {
					console.log("Qwilr API data", data)
					setPage({
						link: apiData.links.publicUrl,
						// Extract the secret component, as the last segnment of the URL, split by dashes
						secret: apiData.links.publicUrl.split('-').pop().trim(),
						clientAppHost
					})
					
					nextStep()
				}
			})
		})
		.catch( err => {
			console.error(err)
			setError(true)
		})

	}


	// On component load
	useEffect( () => {
		if(!useFakeData){
			getPage()
		} else {

			// Delay for a few seconds with fake data
			new Promise(
				resolve => setTimeout(resolve, 10000)
			).then(nextStep)
		}
	}, [])

	return(
		<div className={styles.loadingContainer}>
			<div className={styles.leftPane}>
				<div className={styles.leftPaneContentWrapper}>
					<Explainer />
				</div>
			</div>
			<div className={styles.rightPane}>
				<div className={styles.rightPaneContentWrapper}>
					{ !useFakeData && <PageBuildProgress sections={sectionNames} /> }
					{ useFakeData && <PageBuildProgress sections={["Introduction", "Other thing", "This that", "Blha blah"]} /> }
				</div>
			</div>

			{/* Mobile */}
			<div className={styles.mobileVersion}>
				<Explainer>
					{ !useFakeData && <PageBuildProgress sections={sectionNames} /> }
					{ useFakeData && <PageBuildProgress sections={["Introduction", "Other thing", "This that", "Blha blah"]} /> }
				</Explainer>
			</div>
			{ error &&
				<Error 
					errorMsg="Darn, the AI engine had a problem creating your page. Let's try one more time."
					buttonLabel="Retry"
					onTryAgain={ () => {
						setError(false)
						getPage() 
					}} />
			}
		</div>
	)
}
