import styles from "./InfoModal.module.css"
import { Card, Text, Heading } from "@qwilr/kaleidoscope";
import Party from "./../../assets/party.svg"
import DottedLine from "./dotted-line.svg"

export default function InfoModal({onDismiss}){
	return(
		<Card elevation={2} className={styles.modal}>
			<header className={styles.header}>
				<img src={Party} alt="Celebrate"/>
				<div>
					<Heading strong level={2}>All done.</Heading>
					<p><Text strong size="l">Your page is ready!</Text></p>
				</div>
			</header>
			<div className={styles.text}>
				<Text size="l">
					<p className={styles.note}>
						The AI technology that powers this builder is still very 
						new and sometimes imperfect; but we hope it gives 
						you a good sense of what is possible with Qwilr.
					</p>

					<p>
						Meantime, if you like what you see and want to start 
						editing the page, you can hit “edit this page” and hop into the app.
					</p>
					
					<p>
						Enjoy!
					</p>
				</Text>
			</div>

      <button 
      	onClick={onDismiss}
      	className={"primary"}>
        Got it
      </button>
		</Card>
	)
}