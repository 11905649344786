import styles from "./Error.module.css"
import ErrorIcon from "./error-icon.svg"
import { Text, Heading } from "@qwilr/kaleidoscope";

export default function Error({onTryAgain, errorMsg, buttonLabel}){

	const withTryAgain = typeof onTryAgain === "function"

	return(
		<div className={styles.errorWrapper}>
			<div className={styles.error}>
				<img src={ErrorIcon} alt="error icon" />
				<Heading strong level={2}>Something went wrong</Heading>
				<p>
					<Text size="xl">{errorMsg || "Darn, there’s a problem with the AI engine. Please try again."}</Text>
				</p>
				{ withTryAgain &&
					<button className="primary" onClick={onTryAgain}>
						{buttonLabel || "Try again"}
					</button>
				}
			</div>
		</div>
	)
}