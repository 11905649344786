import {useState, useEffect} from "react"
import styles from "./LoadingStatements.module.css"

export default function LoadingStatements({loadingStatements, cycleTime = 2500}){
	const [statementIndex, setStatementIndex] = useState(0)
	useEffect(() => {
		const interval = setInterval( () => {
				setStatementIndex( (statementIndex + 1) % loadingStatements.length )
			}, cycleTime
		);

		return () => clearInterval(interval);
	}, [statementIndex]);

	return(
		<span>{loadingStatements[statementIndex]}</span>
	)
}
