import {useState, useEffect, useContext} from "react"
import { 
	Button, 
	ButtonSize, 
	ButtonTheme, 
	IconButton, 
	Text, 
	Card,
	TextInputSize,
	TextInput,
	ButtonType, 
	FormComponent,
	FormTextInput,
	FormSubmitButton,
	Heading,
	FormContext
} from "@qwilr/kaleidoscope";
import VideoTile from "./../Video/VideoTile/VideoTile"
import styles from "./Description.module.css"

export default function Description({description, setDescription, nextStep, showVideo}){

	const onSubmit = (data) => {
		setDescription(data)
		nextStep()
	}

	return(
		<div className={styles.descriptionContainer}>
			<header className={styles.header}>
				<Heading strong level="2">👋 What are we creating?</Heading>

				<div className={styles.templates}>
					<TemplatesSentence setDescription={setDescription} />
				</div>
			</header>

			<FormComponent onSubmit={onSubmit}>
				<div className={styles.inputs}>
					<InputRow 
						label="We're a"
						name="identity"
						placeholder="i.e. B2B marketing software company"
						value={description.identity}
						validate={ (value) => {
							if(!value){
								return "The AI needs to know who/what you are"
							}
						}}
						/>			
					<InputRow 
						label="make me a"
						name="useCase"
						placeholder="i.e. Proposal, renewal, job ad..."
						value={description.useCase}
						validate={ (value) => {
							if(!value){
								return "Let the AI know what kind of document you're making"
							}
						}}
						/>			
					<InputRow 
						label="for"
						name="receiver"
						value={description.receiver}
						placeholder="i.e. IBM, Tesla, Starbucks..."
						/>			
					<InputRow 
						label="to"
						name="purpose"
						type="textArea"
						value={description.purpose}
						placeholder="i.e. be their development partner for the ACME Corporation Account"
						/>
				</div>
				<div className={styles.actionContainer}>
					<button className={styles.button + " primary"} type="submit">
			       Create outline
			    </button>
		     </div>
	     </FormComponent>

				<div className={styles.mobileVideo}>
					<VideoTile showVideo={showVideo} />
				</div>
		</div>
	)
}

function InputRow({placeholder, name, label, validate, value, onChange, type = "text"}){
	let validateFn = {}
	if(typeof validate === "function"){
		validateFn = {
			validate: validate
		}
	}
	let Input
	if(type === "text"){
		Input = (
			<FormTextInput
				labelHidden
				name={name}
				initialValue={value}
				placeholder={placeholder}
				size={TextInputSize.Large}
				{...validateFn}
			/>
		)		
	} else {
		Input = (
			<FormTextInput
				labelHidden
				multiline
				initialValue={value}
				name={name}
				size={TextInputSize.Large}
				placeholder={placeholder}
				{...validateFn}
			/>
		)		

	}
	return(
		<div className={styles.inputRow}>
			<label className={styles.inputLabel}>{label}</label>
			{Input}
		</div>
	)
}


function TemplatesSentence({setDescription}){
	const templateList = {
		proposals: {
				identity: "web development agency",
				useCase: "proposal",
				receiver: "Saatchi & Saatchi ad agency",
				purpose: "be their development partner for the ACME Corporation Account"
		},
		renewal: {
			identity: "B2B software company",
			useCase: "account renewal",
			receiver: "ACME Corporation",
			purpose: "renew their annual Enterprise subscription with us"
		},
		jobAd: {
			identity: "technology recruiting firm",
			useCase: "job description",
			receiver: "Senior Software Engineers",
			purpose: "to come work at ACME Corporation, a multi-national specialised in transport and aerospace"
		},
		somethingElse: {
				identity: "international ninja club",
				useCase: "annual summit invitation",
				receiver: "black belts of the world",
				purpose: "to come and compete in our international ninja and karate tournament in downtown LA"
		}
	}

	return(
		<p className={styles.templateSentence}>
			Tell us what you’d like and the Qwilr AI will spin it up for you.
			It could be a <TemplateItem setDescription={setDescription} templateValue={templateList.proposals}>proposal</TemplateItem>, or an <TemplateItem setDescription={setDescription} templateValue={templateList.renewal}>account renewal</TemplateItem>, a <TemplateItem setDescription={setDescription} templateValue={templateList.jobAd}>job ad</TemplateItem>, or <TemplateItem setDescription={setDescription} templateValue={templateList.somethingElse}>anything really</TemplateItem>.
		</p>
	)
}

function TemplateItem({setDescription, children, templateValue}){
	return(
		<span className={styles.templateSentenceItem} onClick={ () => setDescription(templateValue)}>
			{children}
		</span>
	)
}