import {useState, useEffect} from "react"
import {Helmet} from "react-helmet";
import styles from './App.module.css';
import Sidebar from "./Sidebar/Sidebar"
import Description from "./Description/Description"
import Outline from "./Outline/Outline"
import Style from "./Style/Style"
import CreatePage from "./CreatePage/CreatePage"
import PagePreview from "./PagePreview/PagePreview"
import Video from "./Video/Video"
import QwilrLogo from "./assets/qwilr-logo.svg"
import telemetry from "./telemetry/telemetry"

const SEGMENT_WRITE_KEY = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_SEGMENT_WRITE_KEY : process.env.REACT_APP_PROD_SEGMENT_WRITE_KEY

const DEV_DEFAULT_STATE = "description"

// Set this to true if you don't want to actually talk to the server
export const useFakeData = false


function App() {
  const startingState = process.env.NODE_ENV === "development" && useFakeData ? DEV_DEFAULT_STATE : "description"

  const [currentStep, setStep] = useState(startingState)
  const [description, setDescription] = useState({identity: "", useCase: "", receiver: "", purpose: ""})
  const [structure, setStructure] = useState([])
  const [page, setPage] = useState({link: "", secret: "", clientAppHost: ""})
  const [style, setStyle] = useState("modern")
  const [showVideo, setShowVideo] = useState(false)

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		const identity = searchParams.get("identity") ?? "";
		const useCase = searchParams.get("useCase") ?? "";
		const receiver = searchParams.get("receiver") ?? "";
		const purpose = searchParams.get("purpose") ?? "";

		setDescription({ identity, useCase, receiver, purpose });

		if (identity && useCase) {
			setStep("structure")
		}
	}, [])

  const stepLayoutConfig = {
  	description: {
  		showSidebar: true
  	},
  	structure: {
  		showSidebar: true
  	},
  	style: {
  		showSidebar: true
  	},
  	createPage: {
  		showSidebar: false
  	},
  	pageLink: {
  		showSidebar: false
  	},
  	pagePreview: {
  		showSidebar: false
  	}
  }


  // Only for dev.
  const [showTempDataPreview, setShowTempDataPreview] = useState(true)

  let StepComponent;
  if (currentStep === "description"){
    StepComponent = (
    	<Step telemetryStepName="description">
	    	<Description 
		      nextStep={() => setStep("structure")}
		      description={description}
	    		showVideo={ () => setShowVideo(true) }
		      setDescription={setDescription} />
    	</Step>
    )
  } else if (currentStep === "structure"){
    StepComponent = (
    	<Step telemetryStepName="outline">
	    	<Outline
		      structure={structure}
		      setStructure={setStructure} 
		      description={description}
		      dummy={true}
		      nextStep={() => setStep("style")}
		      />
		  </Step>  
		)  
  } else if (currentStep === "style"){
    StepComponent = (
    	<Step telemetryStepName="style">
	    	<Style 
		      style={style}
		      setStyle={setStyle}
		      nextStep={() => setStep("createPage")}
		      setBrandStyle={setStyle} 
		      structure={structure}
		      description={description}
		      />
		  </Step>
		)
  } else if(currentStep === "createPage"){
    StepComponent = (
    	<Step telemetryStepName="create-page">
	    	<CreatePage
		      structure={structure}
		      style={style}
		      description={description}
		      setPage={setPage}
		      nextStep={ () => setStep("pagePreview")}
	    	/>
    	</Step>
    )
  } else if(currentStep === "pagePreview"){
  	let pageData
  	if(useFakeData){
  		pageData = {
	  		link: "https://pages.qwilr.com/Project-Alchemy-Experiment-1-tf7nGIQEO9DJ",
	  		secret: "tf7nGIQEO9DJ"
	  	}
  	} else{
  		pageData = page
  	}
  	
  	StepComponent = (
  		<Step telemetryStepName="page-preview">
  			<PagePreview page={pageData} />
  		</Step>
  	)
  	
  }

  let mainPaneExtraClasses = ""
  if(!stepLayoutConfig[currentStep].showSidebar){
    mainPaneExtraClasses = styles.fullWidth
  }
  if(currentStep === "pagePreview"){
  	mainPaneExtraClasses += ` ${styles.pagePreview}`
  }

  return (
    <div className={styles.app}>
      { stepLayoutConfig[currentStep].showSidebar &&
        <div className={styles.sideBar}>
      		<a href="https://qwilr.com" target="_blank">
	    			<img className={styles.mediumBreakpointLogo} src={QwilrLogo} alt="Qwilr logo" />
	    		</a>
          <Sidebar
          	showVideo={ () => setShowVideo(true) }
            structure={structure}
            description={description}
            currentStep={currentStep} 
            setCurrentStep={setStep} />
        </div>
      }
      <div className={styles.mainPane + " " + mainPaneExtraClasses}>
       	{StepComponent}
      </div>

      { showVideo && <Video closeVideo={ () => setShowVideo(false) } /> }

      <Helmet>
				<script type="text/javascript">
				  {` !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(t){return function(){if(window.analytics.initialized)return window.analytics[t].apply(window.analytics,arguments);var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var key=analytics.methods[t];analytics[key]=analytics.factory(key)}analytics.load=function(key,t){var e=document.createElement("script");e.type="text/javascript";e.async=!0;e.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(e,i);analytics._loadOptions=t};analytics._writeKey="${SEGMENT_WRITE_KEY}";;analytics.SNIPPET_VERSION="4.16.0";
				  analytics.load("${SEGMENT_WRITE_KEY}");
				  analytics.page();
					analytics.track("Website Page Viewed", {
						path: window.location.pathname,
						referrer: document.referrer,
						title: document.title,
						url: window.location.href,
					});
				  }}(); `}
				</script>
				<script type="text/javascript">
				{` 
					var APP_ID = "${process.env.REACT_APP_INTERCOM_APP_ID}";
					(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})(); 
				`}
				</script>
  		</Helmet>
    </div>
  );
}

// Wrapper to fire Telemetry
function Step({children, telemetryStepName}){
	useEffect( () => {
		telemetry.trackEvent("Loaded Page Builder Step", {
			step_name: telemetryStepName
		})
	}, [telemetryStepName])

	return(
		<>
			{children}
		</>
	)
}

export default App;
